import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import RowListing from "./rowListing";
import DataTable2 from "./DataTable10institute";

const DataTable = ({
  transactions,
  usdPrice,
  user,
  setSearch,
  setTopLeaderSearch,
  GetSearchData,
  getTotalList,
  setOrderDirection,
  setOrderField,
  setActiveTab,
  activeTab,
  topTenLeaders,
  topTenApiCalled,
  transactionApiCalled,
}) => {
  const setSearching = (e) => {
    if (e === "") {
      // getTotalList();
      // GetSearchData();
    } else {
      setSearch(e);
    }
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setSearch("");
    setTopLeaderSearch("");
  };

  return (
    <>
      <section className="datatable">
        <div className="main-heading">
          {/* <h6>Latest Transaction</h6> */}
          <ul class="nav nav-tabs viewdetail-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "home-tab" ? "active" : ""
                }`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected={activeTab === "home-tab"}
                onClick={() => handleTabClick("home-tab")}
              >
                Latest Transaction
              </button>
            </li>
            {user?.role != "agent" ? (
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "profile-tab" ? "active" : ""
                  }`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected={activeTab === "profile-tab"}
                  onClick={() => handleTabClick("profile-tab")}
                >
                  Top 10 Institutions
                </button>
              </li>
            ) : null}
          </ul>
          <div className="parent-twice">
            {/* <div className="custom-option-field">
              <input
                type="search"
                onChange={(e) => {
                  if (activeTab == "profile-tab") {
                    setTopLeaderSearch(e.target.value);
                  } else {
                    setSearch(e.target.value);
                  }
                }}
                placeholder="Search..."
              />
              <img
                style={{ cursor: "pointer" }}
                src="\assets\search-icon.svg"
                alt="img"
                className="img-fluid search-icon"
              />
            </div> */}
            {/* <button className="reset-btn-global">Reset</button> */}
          </div>
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            {transactionApiCalled ? (
              <>
                {transactions?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>
                              <div className="parent-tag">
                                Date{" "}
                                <div
                                  className="filter-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrderField("transactionTime");
                                    setOrderDirection((prev) =>
                                      prev == -1 ? 1 : prev == 1 ? -1 : -1
                                    );
                                  }}
                                >
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Hash{" "}
                                <div className="filter-btn">
                                  {/* <img src={tableup} alt="img" className="img-fluid" />
                          <img
                            src={tabledown}
                            alt="img"
                            className="img-fluid"
                          /> */}
                                </div>
                              </div>
                            </th>
                            {/* <th>
                              <div className="parent-tag">
                                Sale Type{" "}
                              </div>
                            </th> */}
                            <th>
                              <div className="parent-tag">Name </div>
                            </th>
                            <th>
                              <div className="parent-tag">Recpient Wallet </div>
                            </th>
                            <th>
                              <div className="parent-tag">Buyer Email </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Wallet Address{" "}
                                <div className="filter-btn"></div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Amount{" "}
                                <div
                                  className="filter-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrderField("amountInvested");
                                    setOrderDirection((prev) =>
                                      prev == -1 ? 1 : prev == 1 ? -1 : -1
                                    );
                                  }}
                                >
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                INCENTIV{" "}
                                <div
                                  className="filter-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrderField("dopPurchased");
                                    setOrderDirection((prev) =>
                                      prev == -1 ? 1 : prev == 1 ? -1 : -1
                                    );
                                  }}
                                >
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            {/* {user?.role == "admin" ? (
                              <th>
                                <div className="parent-tag">
                                  Net Rev{" "}
                                  <div className="filter-btn">
                                    <img
                                      style={{ cursor: "pointer" }}
                                      src={tableup}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    <img
                                      style={{ cursor: "pointer" }}
                                      src={tabledown}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                              </th>
                            ) : (
                              ""
                            )} */}
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.slice(0, 10).map((item, index) => {
                            return (
                              <RowListing
                                user={user}
                                item={item}
                                index={index}
                                usdPrice={usdPrice}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="footer-content">
                        <div className="left-f">
                          {/* <h6>SHOWING 1-10 OF 145</h6> */}
                        </div>
                        <div className="right-f">
                          {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
                        </div>
                      </div>
                    </div>
                    <div className="mobiledesignss d-none">
                      <Accordion>
                        {transactions?.slice(0, 10).map((item, index) => {
                          return (
                            <RowListing
                              user={user}
                              item={item}
                              index={index}
                              usdPrice={usdPrice}
                            />
                          );
                        })}
                      </Accordion>
                    </div>
                  </>
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>
          <div
            class="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            {topTenApiCalled ? (
              <>
                {topTenLeaders?.length > 0 ? (
                  <DataTable2 agents={topTenLeaders} sub={true} />
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default DataTable;
