import "../../App.scss";
import React, { useState, useEffect } from "react";
import Navbar from "./header/Navbar";
import Dashboard from "./Dashboard/Dashboard";
import AllAgents from "./AllAgents/AllAgents";
import AllTransaction from "./AllTransaction/AllTransaction";
import SalesRound from "./SalesRound/SalesRound";

import Claim from "./AllClaim/Claim";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import LeadersAdmin from "./LeaderAdmin/LeadersAdmin";
import Accordion from "react-bootstrap/Accordion";
import InstitutionalStat from "./Institutions/InstitutionalStat";
import AllInstitution from "./AllInstitution/AllInstitution";
import InstituteTransaction from "./InstituteTransaction/InstituteTransaction";
import Tokensettings from "./Tokensettings/Tokensettings";
import TestnetRound from "./TestnetRound/TestnetRound";
import LowCreditError from "./LowCreditError/LowCreditError";
import Stats from "./Stats/Stats";
import InsuranceDetail from "./InsuranceDetail/InsuranceDetail";
import Topccountry from "./Dashboard/Topccountry";
import Topamount from "./Dashboard/Topamount";

function Landing() {
  const indexvv = localStorage.getItem("indexvalue");
  const [indexwait, setindexwait] = useState(0);
  const [routes, setRoutes] = useState(false);
  const [user, setUser] = useState(null);

  const val = localStorage.getItem("accessToken");
  const [usdPrice, setUsdPrice] = useState("");
  const [tomiPrice, setTomiPrice] = useState("");
  const [btcPrice, setBtcPrice] = useState("");
  const [pepePrice, setPepePrice] = useState("");
  const [linkPrice, setLinkPrice] = useState("");
  const [uniPrice, setUniPrice] = useState("");
  const [dopPrice, setDopPrice] = useState("");

  useEffect(() => {
    if (!tomiPrice) {
      getTomiPrice();
    }
    if (!usdPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
        .then((res) => {
          setUsdPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!btcPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD")
        .then((res) => {
          setBtcPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }

    if (!pepePrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=PEPE&tsyms=USD")
        .then((res) => {
          setPepePrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!linkPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=LINK&tsyms=USD")
        .then((res) => {
          setLinkPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }
    if (!uniPrice) {
      axios
        .get("https://min-api.cryptocompare.com/data/price?fsym=UNI&tsyms=USD")
        .then((res) => {
          setUniPrice(res?.data?.USD);
          // setEthUsd(res.data);
        });
    }

    if (!dopPrice) {
      axios
        .get(
          `https://mainnet-api-prod.dop.org/transactions/get-price?coinId=data-ownership-protocol`
        )
        .then((res) => {
          const data = res?.data?.data?.market_data?.current_price?.usd;

          setDopPrice(data);
          // setEthUsd(res.data);
        });
    }
  }, []);

  const getTomiPrice = async () => {
    try {
      var config = {
        method: "get",
        url: `${Api_Url}/users/tomi-price`,
      };

      axios(config)
        .then(function (res) {
          setTomiPrice(res?.data?.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      // throw error;
    }
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (!val) {
        // Token is not present, user is not logged in
        return;
      }

      // Split the JWT into its parts (header, payload, and signature)
      const parts = val?.split(".");
      if (parts.length !== 3) {
        // Invalid JWT format
        return;
      }

      // Decode the payload
      const payload = JSON.parse(atob(parts[1]));

      // Get the expiration time from the payload
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      if (expirationTime < currentTime) {
        // Token has expired
        Logout(); // Call the logout function
      }
    };

    // Check token expiry every minute (you can adjust the interval as needed)
    const intervalId = setInterval(checkTokenExpiry, 60000);
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const history = useHistory();
  useEffect(() => {
    if (val) {
    } else {
      history.push("/login");
    }
    if (indexvv == "0") {
      setindexwait(0);
    } else if (indexvv == "1") {
      setindexwait(1);
    } else if (indexvv == "2") {
      setindexwait(2);
    } else if (indexvv == "3") {
      setindexwait(3);
    } else if (indexvv == "4") {
      setindexwait(4);
    } else if (indexvv == "5") {
      setindexwait(5);
    } else if (indexvv == "6") {
      setindexwait(6);
    } else if (indexvv == "7") {
      setindexwait(7);
    } else if (indexvv == "8") {
      setindexwait(8);
    } else if (indexvv == "9") {
      setindexwait(9);
    } else if (indexvv == "10") {
      setindexwait(10);
    } else if (indexvv == "11") {
      setindexwait(11);
    } else if (indexvv == "12") {
      setindexwait(12);
    }
    // else if (indexvv == "16") {
    //   setindexwait(16);
    // }
  }, []);
  const hitfunctionss = (asd) => {
    setindexwait(asd);
    localStorage.setItem("indexvalue", asd);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let res = localStorage.getItem("userId");
    res = JSON.parse(res);
    GetMyData();
    // setUser(res);
  }, []);

  const GetMyData = async () => {
    const tok = localStorage?.getItem("accessToken");
    // if (account) {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my/stats?privateSale=INCENTIV`,
      // remove the private sale url from here
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    axios(config)
      .then(function (response) {
        setUser(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // history.push("/login");
        }
        console.log(error);
      });
    // }
  };
  // console.log("user?.role", user?.role);
  const [sidebar, setSidebar] = useState(false);

  const Logout = async () => {
    const val = localStorage.getItem("accessToken");
    var config = {
      method: "delete",
      url: `${Api_Url}/auth/users/logout`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.statusCode == 200) {
          localStorage?.removeItem("accessToken");
          localStorage?.removeItem("refreshToken");
          localStorage?.removeItem("userName");
          history.push("/login");
        }
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
      });
  };

  return (
    <>
      <Navbar
        sidebar={sidebar}
        setSidebar={setSidebar}
        user={user}
        GetMyData={GetMyData}
        setindexwait={setindexwait}
        indexwait={indexwait}
      />
      {indexwait == 0 ? (
        <>
          <Dashboard
            setUser={setUser}
            user={user}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
            hitfunctionss={hitfunctionss}
          />
        </>
      ) : indexwait == 1 ? (
        <>
          <AllAgents
            GetMyData={GetMyData}
            setindexwait={setindexwait}
            user={user}
            setRoutes={setRoutes}
            routes={routes}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
          />
        </>
      ) : indexwait == 2 ? (
        <AllTransaction
          setindexwait={setindexwait}
          user={user}
          tomiPrice={tomiPrice}
          usdPrice={usdPrice}
          btcPrice={btcPrice}
          pepePrice={pepePrice}
          linkPrice={linkPrice}
          uniPrice={uniPrice}
          dopPrice={dopPrice}
        />
      ) : indexwait == 3 ? (
        <>
          {user?.role === "admin" && (
            <SalesRound
              setindexwait={setindexwait}
              tomiPrice={tomiPrice}
              usdPrice={usdPrice}
              btcPrice={btcPrice}
              pepePrice={pepePrice}
              linkPrice={linkPrice}
              uniPrice={uniPrice}
              dopPrice={dopPrice}
            />
          )}
        </>
      ) : indexwait == 4 ? (
        <>
          {
            user?.role === "admin" ? (
              <Claim
                setindexwait={setindexwait}
                tomiPrice={tomiPrice}
                usdPrice={usdPrice}
                btcPrice={btcPrice}
                pepePrice={pepePrice}
                linkPrice={linkPrice}
                uniPrice={uniPrice}
                dopPrice={dopPrice}
              />
            ) : null
            // <LeadersAdmin
            //   setindexwait={setindexwait}
            //   tomiPrice={tomiPrice}
            //   usdPrice={usdPrice}
            //   btcPrice={btcPrice}
            //   user={user}
            // />
          }
        </>
      ) : indexwait == 5 ? (
        <>
          <InstitutionalStat
            user={user}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
          />
        </>
      ) : indexwait == 6 ? (
        <>
          <AllInstitution
            user={user}
            routes={routes}
            setRoutes={setRoutes}
            setindexwait={setindexwait}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
          />
        </>
      ) : indexwait == 7 ? (
        <>
          <InstituteTransaction
            setindexwait={setindexwait}
            user={user}
            tomiPrice={tomiPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
          />
        </>
      ) : indexwait == 8 ? (
        <>
          <Tokensettings
            user={user}
            routes={routes}
            setRoutes={setRoutes}
            setindexwait={setindexwait}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
          />
        </>
      ) : indexwait == 10 ? (
        <>
          <LowCreditError setindexwait={setindexwait} />
        </>
      ) : indexwait == 11 ? (
        <>
          <Stats user={user} usdPrice={usdPrice} />
        </>
      ) : indexwait == 12 ? (
        <>
          <InsuranceDetail
            setindexwait={setindexwait}
            user={user}
            tomiPrice={tomiPrice}
            usdPrice={usdPrice}
            btcPrice={btcPrice}
            pepePrice={pepePrice}
            linkPrice={linkPrice}
            uniPrice={uniPrice}
            dopPrice={dopPrice}
          />
        </>
      ) : indexwait == 13 ? (
        <>
          <Topccountry />
        </>
      ) : indexwait == 14 ? (
        <>
          <Topamount />
        </>
      ) : (
        // : indexwait == 9 ? (
        //   <>
        //     <TestnetRound
        //       user={user}
        //       tomiPrice={tomiPrice}
        //       btcPrice={btcPrice}
        //       usdPrice={usdPrice}
        //     />
        //   </>
        // )
        ""
      )}

      {sidebar && (
        <div className="custom-sidebar">
          <div className="custom-sidebar-top-menu d-none">
            <a onClick={() => setSidebar(!sidebar)} class="navbar-brand">
              <img
                src={
                  sidebar ? "/assets/Close-icon-black.svg" : "/assets/bar.svg"
                }
                alt="img"
                className="img-fluid"
              />
            </a>
            <a
              class="navbar-brand"
              onClick={() => {
                setindexwait(0);
                setSidebar(false);
              }}
            >
              <img src="\logo.svg" alt="img" className="img-fluid" />
            </a>
          </div>
          <ul>
            <li>
              <a
                className={indexwait === 0 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(0);
                  setSidebar(false);
                }}
              >
                <img
                  src="\assets\sidebar\dashboard.svg"
                  alt="img"
                  className="img-fluid"
                />
                Dashboard
              </a>
            </li>
            {user?.role !== "mini_agent" && (
              <li>
                <a
                  className={indexwait === 1 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(1);
                    setSidebar(false);
                    setRoutes(false);
                  }}
                >
                  <img
                    src="\assets\sidebar\allagents.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  All Leaders
                </a>
              </li>
            )}
            <li>
              <a
                className={indexwait === 2 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(2);
                  setSidebar(false);
                }}
              >
                <img
                  src="\assets\sidebar\allltransaction.svg"
                  alt="img"
                  className="img-fluid"
                />
                All Transactions
              </a>
            </li>
            {user?.role === "admin" && (
              <>
                <li>
                  <a
                    className={indexwait === 3 ? "active" : ""}
                    onClick={() => {
                      hitfunctionss(3);
                      setSidebar(false);
                    }}
                  >
                    <img
                      src="\assets\sidebar\salesround.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    Sale Rounds
                  </a>
                </li>
              </>
            )}
            {
              user?.role === "admin" ? (
                <li>
                  <a
                    className={indexwait === 4 ? "active" : ""}
                    onClick={() => {
                      hitfunctionss(4);
                      setSidebar(false);
                    }}
                  >
                    <img
                      src="\assets\sidebar\leaderclaim.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    Leader Claims
                  </a>
                </li>
              ) : null
              // <li>
              //   <a
              //     className={indexwait === 3 ? "active" : ""}
              //     onClick={() => {
              //       hitfunctionss(4);
              //       setSidebar(false);
              //     }}
              //   >
              //     <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       width="24"
              //       height="24"
              //       viewBox="0 0 24 24"
              //       fill="none"
              //     >
              //       <path
              //         d="M20.1611 11.2583H3.83883C3.01932 11.2583 2.35498 11.9226 2.35498 12.7421V20.1614C2.35498 20.9809 3.01932 21.6452 3.83883 21.6452H20.1611C20.9806 21.6452 21.645 20.9809 21.645 20.1614V12.7421C21.645 11.9226 20.9806 11.2583 20.1611 11.2583Z"
              //         stroke="white"
              //         stroke-width="1.5"
              //         stroke-linecap="round"
              //         stroke-linejoin="round"
              //       />
              //       <path
              //         d="M6.80664 4.58075V7.54844M12.0001 2.35498V7.54844M17.1936 4.58075V7.54844"
              //         stroke="white"
              //         stroke-width="1.5"
              //         stroke-linecap="round"
              //         stroke-linejoin="round"
              //       />
              //       <path
              //         d="M11.9999 18.6771C13.2292 18.6771 14.2257 17.6806 14.2257 16.4514C14.2257 15.2221 13.2292 14.2256 11.9999 14.2256C10.7707 14.2256 9.77417 15.2221 9.77417 16.4514C9.77417 17.6806 10.7707 18.6771 11.9999 18.6771Z"
              //         stroke="white"
              //         stroke-width="1.5"
              //         stroke-linecap="round"
              //         stroke-linejoin="round"
              //       />
              //     </svg>
              //     My Claims
              //   </a>
              // </li>
            }
            {user?.role === "admin" && (
              <li>
                <Accordion className="institute-accord">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <a
                        className={
                          indexwait === 5 || indexwait === 6 || indexwait === 7
                            ? "d-flex justify-content-between align-center active"
                            : "d-flex justify-content-between align-center"
                        }
                      >
                        <div>
                          <img
                            src="\assets\sidebar\institutional.svg"
                            alt="img"
                            className="img-fluid me-2"
                          />
                          Institutions
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                        >
                          <path
                            d="M12.9181 0.178772H6.6881H1.0781C0.1181 0.178772 -0.361899 1.33877 0.318101 2.01877L5.4981 7.19877C6.3281 8.02877 7.6781 8.02877 8.5081 7.19877L10.4781 5.22877L13.6881 2.01877C14.3581 1.33877 13.8781 0.178772 12.9181 0.178772Z"
                            fill="black"
                          />
                        </svg>
                      </a>
                    </Accordion.Header>
                    <Accordion.Body>
                      <a
                        onClick={() => {
                          hitfunctionss(5);
                          setSidebar(false);
                        }}
                      >
                        Institutions Stats
                      </a>
                      <a
                        onClick={() => {
                          hitfunctionss(6);
                          setSidebar(false);
                        }}
                      >
                        All Institutions
                      </a>
                      <a
                        onClick={() => {
                          hitfunctionss(7);
                          setSidebar(false);
                        }}
                      >
                        All Transactions
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </li>
            )}
            {user?.role == "admin" ? (
              <li>
                <a
                  className={indexwait === 8 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(8);
                    setSidebar(false);
                  }}
                >
                  <img
                    src="\assets\sidebar\tokensettings.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  Token Settings
                </a>
              </li>
            ) : null}
            {/* {user?.role == "admin" ? (
              <li>
                <a
                  className={indexwait === 11 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(11);
                    setSidebar(false);
                  }}
                >
                  <img
                    src="\assets\sidebar\stats.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  Stats
                </a>
              </li>
            ) : null} */}
            {user?.role == "admin" ? (
              <li>
                <a
                  className={indexwait === 10 ? "active" : ""}
                  onClick={() => {
                    hitfunctionss(10);
                    setSidebar(false);
                  }}
                >
                  <img
                    src="\assets\sidebar\lowcrediterror.svg"
                    alt="img"
                    className="img-fluid"
                  />
                  Low Credit Errors
                </a>
              </li>
            ) : null}
            {/* <li>
              <a
                className={indexwait === 12 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(12);
                  setSidebar(false);
                }}
              >
                <img
                  src="\assets\sidebar\inusrancedetail.svg"
                  alt="img"
                  className="img-fluid"
                />
                Insurance Details
              </a>
            </li> */}
            {/* <li>
              <a
                className={indexwait === 9 ? "active" : ""}
                onClick={() => {
                  hitfunctionss(9);
                  setSidebar(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M17.9998 7.91002C17.9698 7.91002 17.9498 7.91002 17.9198 7.91002H17.8698C15.9798 7.85002 14.5698 6.39001 14.5698 4.59001C14.5698 2.75001 16.0698 1.26001 17.8998 1.26001C19.7298 1.26001 21.2298 2.76001 21.2298 4.59001C21.2198 6.40001 19.8098 7.86001 18.0098 7.92001C18.0098 7.91001 18.0098 7.91002 17.9998 7.91002ZM17.8998 2.75002C16.8898 2.75002 16.0698 3.57002 16.0698 4.58002C16.0698 5.57002 16.8398 6.37002 17.8298 6.41002C17.8398 6.40002 17.9198 6.40002 18.0098 6.41002C18.9798 6.36002 19.7298 5.56002 19.7398 4.58002C19.7398 3.57002 18.9198 2.75002 17.8998 2.75002Z"
                    fill="white"
                  />
                  <path
                    d="M18.01 15.28C17.62 15.28 17.23 15.25 16.84 15.18C16.43 15.11 16.16 14.72 16.23 14.31C16.3 13.9 16.69 13.63 17.1 13.7C18.33 13.91 19.63 13.68 20.5 13.1C20.97 12.79 21.22 12.4 21.22 12.01C21.22 11.62 20.96 11.24 20.5 10.93C19.63 10.35 18.31 10.12 17.07 10.34C16.66 10.42 16.27 10.14 16.2 9.73002C16.13 9.32002 16.4 8.93003 16.81 8.86003C18.44 8.57003 20.13 8.88002 21.33 9.68002C22.21 10.27 22.72 11.11 22.72 12.01C22.72 12.9 22.22 13.75 21.33 14.35C20.42 14.95 19.24 15.28 18.01 15.28Z"
                    fill="white"
                  />
                  <path
                    d="M5.96998 7.91C5.95998 7.91 5.94998 7.91 5.94998 7.91C4.14998 7.85 2.73998 6.39 2.72998 4.59C2.72998 2.75 4.22998 1.25 6.05998 1.25C7.88998 1.25 9.38998 2.75 9.38998 4.58C9.38998 6.39 7.97998 7.85 6.17998 7.91L5.96998 7.16L6.03998 7.91C6.01998 7.91 5.98998 7.91 5.96998 7.91ZM6.06998 6.41C6.12998 6.41 6.17998 6.41 6.23998 6.42C7.12998 6.38 7.90998 5.58 7.90998 4.59C7.90998 3.58 7.08998 2.75999 6.07998 2.75999C5.06998 2.75999 4.24998 3.58 4.24998 4.59C4.24998 5.57 5.00998 6.36 5.97998 6.42C5.98998 6.41 6.02998 6.41 6.06998 6.41Z"
                    fill="white"
                  />
                  <path
                    d="M5.96 15.28C4.73 15.28 3.55 14.95 2.64 14.35C1.76 13.76 1.25 12.91 1.25 12.01C1.25 11.12 1.76 10.27 2.64 9.68002C3.84 8.88002 5.53 8.57003 7.16 8.86003C7.57 8.93003 7.84 9.32002 7.77 9.73002C7.7 10.14 7.31 10.42 6.9 10.34C5.66 10.12 4.35 10.35 3.47 10.93C3 11.24 2.75 11.62 2.75 12.01C2.75 12.4 3.01 12.79 3.47 13.1C4.34 13.68 5.64 13.91 6.87 13.7C7.28 13.63 7.67 13.91 7.74 14.31C7.81 14.72 7.54 15.11 7.13 15.18C6.74 15.25 6.35 15.28 5.96 15.28Z"
                    fill="white"
                  />
                  <path
                    d="M11.9998 15.38C11.9698 15.38 11.9498 15.38 11.9198 15.38H11.8698C9.97982 15.32 8.56982 13.86 8.56982 12.06C8.56982 10.22 10.0698 8.72998 11.8998 8.72998C13.7298 8.72998 15.2298 10.23 15.2298 12.06C15.2198 13.87 13.8098 15.33 12.0098 15.39C12.0098 15.38 12.0098 15.38 11.9998 15.38ZM11.8998 10.22C10.8898 10.22 10.0698 11.04 10.0698 12.05C10.0698 13.04 10.8398 13.84 11.8298 13.88C11.8398 13.87 11.9198 13.87 12.0098 13.88C12.9798 13.83 13.7298 13.03 13.7398 12.05C13.7398 11.05 12.9198 10.22 11.8998 10.22Z"
                    fill="white"
                  />
                  <path
                    d="M11.9998 22.76C10.7998 22.76 9.59978 22.45 8.66978 21.82C7.78978 21.23 7.27979 20.39 7.27979 19.49C7.27979 18.6 7.77978 17.74 8.66978 17.15C10.5398 15.91 13.4698 15.91 15.3298 17.15C16.2098 17.74 16.7198 18.58 16.7198 19.48C16.7198 20.37 16.2198 21.23 15.3298 21.82C14.3998 22.44 13.1998 22.76 11.9998 22.76ZM9.49979 18.41C9.02979 18.72 8.77979 19.11 8.77979 19.5C8.77979 19.89 9.03979 20.27 9.49979 20.58C10.8498 21.49 13.1398 21.49 14.4898 20.58C14.9598 20.27 15.2098 19.88 15.2098 19.49C15.2098 19.1 14.9498 18.72 14.4898 18.41C13.1498 17.5 10.8598 17.51 9.49979 18.41Z"
                    fill="white"
                  />
                </svg>
                Testnet Round
              </a>
            </li> */}
          </ul>
          <Navbar setSidebar={setSidebar} mobileNav={true} />
          <button onClick={() => Logout()} className="logout-btn d-none">
            <img
              src="\assets\logout-icon.svg"
              alt="img"
              className="img-fluid"
            />
            Logout
          </button>
        </div>
      )}
    </>
  );
}

export default Landing;
