import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import RowListing from "./rowListings";
import { CSVLink } from "react-csv";
import { Api_Url } from "../../../../utils/ApiUrl";
import { toast } from "react-toastify";
const DataTable = ({
  transactions,
  usdPrice,
  user,
  search,
  setSearch,
  setTransactions,
  setOrderDirection,
  setOrderField,
  handlePageClick,
  tok,
}) => {
  const [claimsCSVData, setClaimsCSVData] = useState([]);
  const [claimsCSVDataHeaders, setClaimsCSVDataHeaders] = useState([]);
  const [loading, setLoading] = useState("");
  const [showbtn, setShowBtn] = useState(true);
  const [data, setexceldata] = useState([]);

  const toastErrorFunc = () => {
    toast.error("No data found to export");
  };
  const getAllClaimsData = async () => {
    setLoading(true);
    setShowBtn(false);

    var config = {
      responseType: "stream",
      url: `${Api_Url}/users/bulk-transaction-export?privateSale=INCENTIV`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(config.url, {
        method: "GET",
        headers: {
          Authorization: config.headers.Authorization,
          // Accept: "text/event-stream",
        },
      });
      if (response.status == 429) {
        toast.error("Limit exceeded. Please try again in one minutes.");
        setTimeout(() => {
          setLoading(false);
          setShowBtn(true);
        }, 3000);
      }
      const reader = response.body.getReader();

      let oldData = "";

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }
        oldData += new TextDecoder().decode(value);
      }
      try {
        const parsedData = JSON.parse(oldData);

        const dataForCsv = parsedData?.map((item) => {
          let date = moment(item?.transactionTime).format("DD/MM/YYYY");
          let time = moment(item?.transactionTime).format("hh:mm A");
          let name = item?.user[0]?.name;
          let role =
            item?.user[0]?.role === "mini_agent"
              ? "Ambassador"
              : item?.user[0]?.role === "god_agent"
              ? "God Leader"
              : item?.user[0]?.role === "super_agent"
              ? "Super Leader"
              : item?.user[0]?.role === "agent"
              ? "Leader"
              : item?.user[0]?.role === "mega_agent"
              ? "Mega Leader"
              : "";
          let code = item?.code_code;
          let Hash = item?.transactionHash;
          let walletAddress = item?.by;
          let price = item?.price;
          let paid = item?.totalSaleConverted;
          let amount = item?.dopPurchased;
          let payment = item?.amountInvested;
          let type = item?.type;
          return {
            date: date,
            time: time,
            name: name,
            role: role,
            code: code,
            Hash: Hash,
            walletAddress: walletAddress,
            price: price,
            paid: paid,
            amount: amount,
            payment: payment,
            type: type,
          };
        });
        const headers = [
          { label: "date", key: "date" },
          { label: "time", key: "time" },
          { label: "name", key: "name" },
          { label: "role", key: "role" },
          { label: "code", key: "code" },
          { label: "Hash", key: "Hash" },
          { label: "walletAddress", key: "walletAddress" },
          { label: "price", key: "price" },
          { label: "paid", key: "paid" },
          { label: "amount", key: "amount" },
          { label: "payment", key: "payment" },
          { label: "type", key: "type" },
        ];
        setClaimsCSVData(dataForCsv);
        setClaimsCSVDataHeaders(headers);

        setexceldata(dataForCsv);
        setLoading(false);
      } catch (error) {
        console.error("Error parsing JSON data:", error);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <section className="datatable">
        {transactions?.length == 0 && (
          <div className="upperbtn">
            <button onClick={toastErrorFunc} className="mygreybtn mb-2">
              click to export
            </button>
          </div>
        )}
        {transactions?.length != 0 && (
          <div className="mb-3">
            <div className="upperbtn">
              {showbtn && (
                <button onClick={getAllClaimsData} className="mygreybtn">
                  click to export
                </button>
              )}
              {loading ? (
                <button className="mygreybtn">
                  <div className="text-center">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </button>
              ) : (
                <>
                  {claimsCSVData && claimsCSVData.length > 0 && (
                    <CSVLink
                      data={claimsCSVData}
                      headers={claimsCSVDataHeaders}
                      filename={"Transactions_data.csv"}
                      className="mygreybtn"
                    >
                      Export CSV
                    </CSVLink>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div className="table-responsive desktopviewss">
          <table class="table">
            <thead>
              <tr>
                <th>
                  <div className="parent-tag">
                    Date{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("transactionTime");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Name{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Code{" "}
                    <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">IP/Country </div>
                </th>
                {user?.role == "admin" ? (
                  <th>
                    <div className="parent-tag">
                      Hash{" "}
                      {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                    </div>
                  </th>
                ) : null}
                <th>
                  <div className="parent-tag">
                    Wallet Address{" "}
                    {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Price{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("amountInvested");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Paid{" "}
                    <div
                      className="filter-btn"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOrderField("dopPurchased");
                        setOrderDirection((prev) =>
                          prev == -1 ? 1 : prev == 1 ? -1 : -1
                        );
                      }}
                    >
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                          fill="#242627"
                        />
                      </svg>
                      <svg
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                          fill="#242627"
                        />
                      </svg>
                    </div>
                  </div>
                </th>

                <th>
                  <div className="parent-tag">AMOUNT</div>
                </th>

                <th>
                  <div className="parent-tag">
                    PAYMENT
                    <div className="filter-btn">
                      {/* <img
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    Type
                    <div className="filter-btn">
                      {/* <img
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parent-tag">
                    my Earning
                    <div className="filter-btn">
                      {/* <img
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      /> */}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((item, index) => {
                return (
                  <RowListing
                    user={user}
                    item={item}
                    index={index}
                    usdPrice={usdPrice}
                  />
                );
              })}
            </tbody>
          </table>
          {/* <div className="footer-content">
            <div className="left-f">
              <h6>SHOWING 1-10 OF 145</h6>
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {"<"}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {">"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
        <div className="mobiledesignss d-none">
          {transactions?.map((item, index) => {
            return (
              <RowListing
                user={user}
                item={item}
                index={index}
                usdPrice={usdPrice}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};

export default DataTable;
