import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../../assets/tableup.svg";
import tabledown from "../../../../assets/tabledown.svg";
import { Accordion } from "react-bootstrap";
import moment from "moment";
import RowListing from "./rowListing";
import DataTableSpecialAccess from "./SpecialAccessTable";
import DataTable2 from "./TopTenLeadersTable";

const DataTable = ({
  btcPrice,
  tomiPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  transactions,
  usdPrice,
  user,
  setSearch,
  setTopLeaderSearch,
  GetSearchData,
  getTotalList,
  setOrderDirection,
  setOrderField,
  setActiveTab,
  activeTab,
  topTenLeaders,
  topTenData,
  topTenApiCalled,
  transactionApiCalled,
  topThreeApiCalled,
  topThreeData,
}) => {
  const setSearching = (e) => {
    if (e === "") {
      // getTotalList();
      // GetSearchData();
    } else {
      setSearch(e);
    }
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setSearch("");
    setTopLeaderSearch("");
  };

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }
  return (
    <>
      <section className="datatable">
        <div className="main-heading">
          {/* <h6>Latest Transaction</h6> */}
          <ul class="nav nav-tabs viewdetail-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeTab === "home-tab" ? "active" : ""
                }`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected={activeTab === "home-tab"}
                onClick={() => handleTabClick("home-tab")}
              >
                Latest Transaction
              </button>
            </li>
            {user?.role != "mini_agent" ? (
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "profile-tab" ? "active" : ""
                  }`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected={activeTab === "profile-tab"}
                  onClick={() => handleTabClick("profile-tab")}
                >
                  Top 10 Leaders
                </button>
              </li>
            ) : null}
            {user?.role == "admin" ? (
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "top-ten" ? "active" : ""
                  }`}
                  id="top-ten"
                  data-bs-toggle="tab"
                  data-bs-target="#top-ten-pane"
                  type="button"
                  role="tab"
                  aria-controls="top-ten-pane"
                  aria-selected={activeTab === "top-ten"}
                  onClick={() => handleTabClick("top-ten")}
                >
                  Top 10
                </button>
              </li>
            ) : user?.role == "god_agent" && user?.specialAccess?.length > 0 ? (
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "special-access" ? "active" : ""
                  }`}
                  id="special-access"
                  data-bs-toggle="tab"
                  data-bs-target="#special-access-pane"
                  type="button"
                  role="tab"
                  aria-controls="special-access-pane"
                  aria-selected={activeTab === "special-access"}
                  onClick={() => handleTabClick("special-access")}
                >
                  Special Access
                </button>
              </li>
            ) : null}
            {user?.role == "admin" ? (
              <li class="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeTab === "top-three" ? "active" : ""
                  }`}
                  id="top-three"
                  data-bs-toggle="tab"
                  data-bs-target="#top-three-pane"
                  type="button"
                  role="tab"
                  aria-controls="top-three-pane"
                  aria-selected={activeTab === "top-three"}
                  onClick={() => handleTabClick("top-three")}
                >
                  Top 3
                </button>
              </li>
            ) : null}
          </ul>
          {/* <div className="parent-twice">
            <div className="custom-option-field">
             
              <input
                type="search"
                onChange={(e) => {
                  if (activeTab == "profile-tab") {
                    setTopLeaderSearch(e.target.value);
                  } else {
                    setSearch(e.target.value);
                  }
                }}
                placeholder="Search..."
              />
             
              <img
                style={{ cursor: "pointer" }}
                src="\assets\search-icon.svg"
                alt="img"
                className="img-fluid search-icon"
                
              />
            </div>
          </div> */}
        </div>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            {transactionApiCalled ? (
              <>
                {transactions?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>
                              <div className="parent-tag">
                                Date{" "}
                                <div
                                  className="filter-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrderField("transactionTime");
                                    setOrderDirection((prev) =>
                                      prev == -1 ? 1 : prev == 1 ? -1 : -1
                                    );
                                  }}
                                >
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Name{" "}
                                {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Code{" "}
                                <div className="filter-btn">
                                  <img
                                    src={tableup}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  <img
                                    src={tabledown}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">IP/Country </div>
                            </th>
                            {user?.role == "admin" ? (
                              <th>
                                <div className="parent-tag">
                                  Hash{" "}
                                  {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                                </div>
                              </th>
                            ) : null}
                            <th>
                              <div className="parent-tag">
                                Wallet Address{" "}
                                {/* <div className="filter-btn">
                      <img src={tableup} alt="img" className="img-fluid" />
                      <img src={tabledown} alt="img" className="img-fluid" />
                    </div> */}
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Price{" "}
                                <div
                                  className="filter-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrderField("amountInvested");
                                    setOrderDirection((prev) =>
                                      prev == -1 ? 1 : prev == 1 ? -1 : -1
                                    );
                                  }}
                                >
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Paid{" "}
                                <div
                                  className="filter-btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrderField("dopPurchased");
                                    setOrderDirection((prev) =>
                                      prev == -1 ? 1 : prev == 1 ? -1 : -1
                                    );
                                  }}
                                >
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                  <svg
                                    width="9"
                                    height="6"
                                    viewBox="0 0 9 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                      fill="#242627"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>

                            <th>
                              <div className="parent-tag">AMOUNT</div>
                            </th>

                            <th>
                              <div className="parent-tag">
                                PAYMENT
                                <div className="filter-btn">
                                  {/* <img
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      /> */}
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                Type
                                <div className="filter-btn">
                                  {/* <img
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      /> */}
                                </div>
                              </div>
                            </th>
                            <th>
                              <div className="parent-tag">
                                My Earning
                                <div className="filter-btn">
                                  {/* <img
                        style={{ cursor: "pointer" }}
                        src={tableup}
                        alt="img"
                        className="img-fluid"
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src={tabledown}
                        alt="img"
                        className="img-fluid"
                      /> */}
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.slice(0, 10).map((item, index) => {
                            return (
                              <RowListing
                                user={user}
                                item={item}
                                index={index}
                                usdPrice={usdPrice}
                                btcPrice={btcPrice}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                      <div className="footer-content">
                        <div className="left-f">
                          {/* <h6>SHOWING 1-10 OF 145</h6> */}
                        </div>
                        <div className="right-f">
                          {/* <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item"><a class="page-link" href="#">{"<"}</a></li>
                  <li class="page-item"><a class="page-link active" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item"><a class="page-link" href="#">{">"}</a></li>
                </ul>
              </nav> */}
                        </div>
                      </div>
                    </div>
                    <div className="mobiledesignss d-none">
                      <Accordion>
                        {transactions?.slice(0, 10).map((item, index) => {
                          return (
                            <RowListing
                              user={user}
                              item={item}
                              index={index}
                              usdPrice={usdPrice}
                              btcPrice={btcPrice}
                            />
                          );
                        })}
                      </Accordion>
                      {/* <Accordion defaultActiveKey="0">
            {transactions?.slice(0, 10).map((item, index) => {
              return (
                <RowListing item={item} index={index} usdPrice={usdPrice} />
              )
            })}
          </Accordion> */}
                      {/* <div className="footer-content">
            <div className="left-f">
              <h6>SHOWING 1-10 OF 145</h6>
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {"<"}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {">"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
                    </div>
                    {/* <div className="new-accordionmobile d-none">
              <div className="upper-body">
                <div className="inner-text">
                  <h6>Date and time</h6>
                  <p>01/03/2023 23:54</p>
                </div>
                <div className="inner-text">
                  <h6>Agent</h6>
                  <p>Sharjeel</p>
                </div>
                <div className="inner-text">
                  <h6>Sale Type</h6>
                  <p>God Agent</p>
                </div>
                <div className="inner-text">
                  <h6>Amount Spend</h6>
                  <p>2.5 ETH</p>
                </div>
              </div>
              <div className="bottom-body">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      More Info{" "}
                      <img
                        src="\assets\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="inner-textt">
                        <p>Hash</p>
                        <h6>Etherscan</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Wallet Address</p>
                        <h6>0x97E68DC...79A</h6>
                      </div>
                      <div className="inner-textt">
                        <p>DOP Purchased</p>
                        <h6>1,000,000,000 DOP</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Leader Earnings</p>
                        <h6>$500 (0.5 ETH)</h6>
                      </div>
                      <div className="inner-textt">
                        <p>Net Revenue</p>
                        <h6>$10 (0.5 ETH)</h6>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div> */}
                  </>
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>
          <div
            class="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            {topTenApiCalled ? (
              <>
                {topTenLeaders?.length > 0 ? (
                  <DataTable2
                    agents={topTenLeaders}
                    sub={true}
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                    pagination={false}
                  />
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>
          <div
            class="tab-pane fade"
            id="top-ten-pane"
            role="tabpanel"
            aria-labelledby="top-ten"
            tabindex="0"
          >
            {topTenApiCalled ? (
              <>
                {topTenData?.length > 0 ? (
                  <DataTableSpecialAccess
                    agents={topTenData}
                    sub={true}
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pagination={false}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                    dopPrice={dopPrice}
                  />
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>
          <div
            class="tab-pane fade"
            id="special-access-pane"
            role="tabpanel"
            aria-labelledby="special-access"
            tabindex="0"
          >
            {topTenApiCalled ? (
              <>
                {topTenData?.length > 0 ? (
                  <DataTableSpecialAccess
                    agents={topTenData}
                    sub={true}
                    tomiPrice={tomiPrice}
                    usdPrice={usdPrice}
                    btcPrice={btcPrice}
                    pagination={false}
                    pepePrice={pepePrice}
                    linkPrice={linkPrice}
                    uniPrice={uniPrice}
                  />
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>

          <div
            class="tab-pane fade"
            id="top-three-pane"
            role="tabpanel"
            aria-labelledby="top-three"
            tabindex="0"
          >
            {topThreeApiCalled ? (
              <>
                {topThreeData?.amountInvested?.length > 0 ||
                topThreeData?.totalPurchases?.length > 0 ? (
                  <>
                    <div className="twice-tables-set">
                      <section className="datatable">
                        <div className="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>
                                  <div className="parent-tag">
                                    No.
                                    <div className="filter-btn">
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="parent-tag">
                                    Leader/Role
                                    <div className="filter-btn">
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="parent-tag">
                                    Total Purchases
                                    <div className="filter-btn">
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {topThreeData?.amountInvested?.length > 0
                                ? topThreeData?.amountInvested?.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>#{index + 1}</td>
                                            <td>
                                              <div className="twice-text">
                                                <h6>{item?.user[0]?.name}</h6>
                                                <p>
                                                  {convertToTitleCase(
                                                    item?.user[0]?.role
                                                  )}
                                                </p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="forflex">
                                                <p>
                                                  $
                                                  {parseFloat(
                                                    item?.dopPurchased
                                                  ).toFixed(2)}
                                                </p>
                                                <svg
                                                  className="showhiddenmain"
                                                  width="19"
                                                  height="18"
                                                  viewBox="0 0 19 18"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <g id="Warning / Info">
                                                    <path
                                                      id="Vector"
                                                      d="M9.16748 8.25V12M9.16748 15.75C5.43956 15.75 2.41748 12.7279 2.41748 9C2.41748 5.27208 5.43956 2.25 9.16748 2.25C12.8954 2.25 15.9175 5.27208 15.9175 9C15.9175 12.7279 12.8954 15.75 9.16748 15.75ZM9.20483 6V6.075L9.13013 6.07515V6H9.20483Z"
                                                      stroke="#C4C4C4"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </g>
                                                </svg>

                                                <div className="hiddenmain">
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      USDT
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalUsdt
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      ETH
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalEth
                                                      ).toFixed(5)}
                                                    </p>
                                                  </div>
                                                  {/* <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      TOMI
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalTomi
                                                      ).toFixed(4)}
                                                    </p>
                                                  </div> */}
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      WBTC
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalWbtc
                                                      ).toFixed(6)}
                                                    </p>
                                                  </div>
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      USDC
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalUsdc
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      PEPE
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalPepe
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>{" "}
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      LINK
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalLink
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>{" "}
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      UNI
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalUni
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>

                        <div className="accmblview d-none">
                          <div className="main-twice-text">
                            <h6 className="accmblviewhead">Total Purchases</h6>
                          </div>
                          {topThreeData?.amountInvested?.length > 0
                            ? topThreeData?.amountInvested?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <Accordion>
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            <div className="accheadermain">
                                              <p className="acctext">
                                                {/* Total Purchases */}
                                                {item?.user[0]?.name}/
                                                {convertToTitleCase(
                                                  item?.user[0]?.role
                                                )}
                                              </p>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div className="acctexts">
                                              <h6 className="textleft">No.</h6>
                                              <p className="textright">
                                                #{index + 1}
                                              </p>
                                            </div>
                                            <div className="acctexts">
                                              <h6 className="textleft">
                                                Leader/Role
                                              </h6>
                                              <p className="textright">
                                                {item?.user[0]?.name}/
                                                {convertToTitleCase(
                                                  item?.user[0]?.role
                                                )}
                                              </p>
                                            </div>
                                            <div className="acctexts">
                                              <h6 className="textleft">
                                                Total Purchases
                                              </h6>
                                              <div className="forflex">
                                                <p>
                                                  $
                                                  {parseFloat(
                                                    item?.dopPurchased
                                                  ).toFixed(2)}
                                                </p>
                                                <svg
                                                  className="showhiddenmain"
                                                  width="19"
                                                  height="18"
                                                  viewBox="0 0 19 18"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <g id="Warning / Info">
                                                    <path
                                                      id="Vector"
                                                      d="M9.16748 8.25V12M9.16748 15.75C5.43956 15.75 2.41748 12.7279 2.41748 9C2.41748 5.27208 5.43956 2.25 9.16748 2.25C12.8954 2.25 15.9175 5.27208 15.9175 9C15.9175 12.7279 12.8954 15.75 9.16748 15.75ZM9.20483 6V6.075L9.13013 6.07515V6H9.20483Z"
                                                      stroke="#C4C4C4"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </g>
                                                </svg>
                                                <div className="hiddenmain">
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      USDT
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalUsdt
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      ETH
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalEth
                                                      ).toFixed(5)}
                                                    </p>
                                                  </div>
                                                  {/* <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      TOMI
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalTomi
                                                      ).toFixed(4)}
                                                    </p>
                                                  </div> */}
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      WBTC
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalWbtc
                                                      ).toFixed(6)}
                                                    </p>
                                                  </div>
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      USDC
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalUsdc
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      PEPE
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalPepe
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>{" "}
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      LINK
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalLink
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>{" "}
                                                  <div className="hiddenflex">
                                                    <h6 className="hiddenhead">
                                                      UNI
                                                    </h6>
                                                    <p className="hiddenpara">
                                                      {parseFloat(
                                                        item?.totalUni
                                                      ).toFixed(2)}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </>
                                  );
                                }
                              )
                            : null}
                        </div>
                      </section>
                      <section className="datatable">
                        <div className="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>
                                  <div className="parent-tag">
                                    No.
                                    <div className="filter-btn">
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="parent-tag">
                                    Leader/Role
                                    <div className="filter-btn">
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div className="parent-tag">
                                    No of Purchases
                                    <div className="filter-btn">
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 5.14282H4.09498H0.660442C0.072714 5.14282 -0.22115 4.36222 0.195157 3.90463L3.36644 0.418863C3.87458 -0.139668 4.70107 -0.139668 5.20921 0.418863L6.41528 1.74453L8.38049 3.90463C8.79068 4.36222 8.49681 5.14282 7.90908 5.14282Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                      <svg
                                        width="9"
                                        height="6"
                                        viewBox="0 0 9 6"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M7.90908 0.857178H4.09498H0.660442C0.072714 0.857178 -0.22115 1.63778 0.195157 2.09537L3.36644 5.58114C3.87458 6.13967 4.70107 6.13967 5.20921 5.58114L6.41528 4.25547L8.38049 2.09537C8.79068 1.63778 8.49681 0.857178 7.90908 0.857178Z"
                                          fill="#242627"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {topThreeData?.totalPurchases?.length > 0
                                ? topThreeData?.totalPurchases?.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>#{index + 1}</td>
                                            <td>
                                              <div className="twice-text">
                                                <h6>{item?.user[0]?.name}</h6>
                                                <p>
                                                  {convertToTitleCase(
                                                    item?.user[0]?.role
                                                  )}
                                                </p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="forflex">
                                                <p>
                                                  {parseFloat(
                                                    item?.purchases
                                                  ).toFixed(2)}
                                                </p>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )
                                : null}
                            </tbody>
                          </table>
                        </div>

                        <div className="accmblview d-none">
                          <div className="main-twice-text">
                            <h6 className="accmblviewhead">No of Purchases</h6>
                          </div>
                          {topThreeData?.totalPurchases?.length > 0
                            ? topThreeData?.totalPurchases?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <Accordion>
                                        <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                            <div className="accheadermain">
                                              <p className="acctext">
                                                {/* No of Purchases */}
                                                {item?.user[0]?.name}/
                                                {convertToTitleCase(
                                                  item?.user[0]?.role
                                                )}
                                              </p>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div className="acctexts">
                                              <h6 className="textleft">No.</h6>
                                              <p className="textright">
                                                #{index + 1}
                                              </p>
                                            </div>
                                            <div className="acctexts">
                                              <h6 className="textleft">
                                                Leader/Role
                                              </h6>
                                              <p className="textright">
                                                {item?.user[0]?.name}/
                                                {convertToTitleCase(
                                                  item?.user[0]?.role
                                                )}
                                              </p>
                                            </div>
                                            <div className="acctexts">
                                              <h6 className="textleft">
                                                No of Purchases
                                              </h6>
                                              <div className="forflex">
                                                <p>
                                                  {parseFloat(
                                                    item?.purchases
                                                  ).toFixed(2)}
                                                </p>
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </>
                                  );
                                }
                              )
                            : null}
                        </div>
                      </section>
                    </div>
                  </>
                ) : (
                  <p
                    className=""
                    style={{
                      color: "#fff",
                      textAlign: "center",
                      marginTop: 100,
                    }}
                  >
                    No data found!
                  </p>
                )}
              </>
            ) : (
              <p
                className=""
                style={{ color: "#fff", textAlign: "center", marginTop: 100 }}
              >
                Loading...
              </p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default DataTable;
